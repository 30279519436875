.Header {
  text-align: center; 
  background-color: rgba(1, 1, 1, 0.3);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;

  div.portfolio-title{
    
    font-size: 14px;
    margin-left: 20px;
    margin-top: 15px;
    display: flex;
    position: absolute;
    left: 0;

    text-transform: uppercase;

    .vs-code-logo {
      margin-right: 10px;
      background-image: url('../../Resources/Images/Fancy/vs-code-icon.png');
      background-size:cover;
      height: 20px;
      width: 20px;
      display:inline-block;
    }
    .first {
      margin-right: 5px;
      font-weight: bold;
    }

    .second {

    }

    .first, .second {

    }
    
  }

  a {
    margin-top: 15px;
    font-size: 14px;
    margin-left: 20px;
    text-decoration: none;

    &.headerTitle {
      color: white
    }
    &.headerTitle.active {
      color: #20a1f1;
    }
  }
}
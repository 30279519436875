.Root {
  text-align: center; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

*{
  
  cursor: url('../../Resources/Images/Cursors/cursor.png'), auto;	
}
.Footer {
    background-color: #020202;
    text-align: center;
    border-top: 1px solid rgba(32, 161, 241, 0.5);
    position: absolute;
    bottom: 0%;
    left: 0px;
    right: 0px;
  }
  
  .Footer-content {
    display: flex;
    justify-content: center;
  }
  
  .Footer-content a {
    color: #61dafb;
    transition: color 0.3s;
    padding: 3.5px 10px;
  }
  
  .Footer-content a:hover {
    color: #21a1f1;
  }
  
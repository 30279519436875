.Sidebar {
  position: fixed;
  top: 7%;
  left: 0;
  bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  
  border-right: 1px solid rgba(32, 161, 241, 0.5);
  box-shadow: 2px 0 2px -2px rgba(201, 234, 255, 0.5);

  .sidebar-knob-holder {
    z-index: 999;
    width:100%;
    margin-bottom: 30px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    cursor: url("../../Resources/Images/Cursors/pointer.png"), auto;

    .sidebar-knob {
      z-index: -1;
      width: 16px;
      height: 16px;
      background-color: rgba(10, 10, 10);
      box-shadow: 1px 1px rgba(152, 209, 245, 0.9);
      border: 1px solid #20a1f1;

      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      display: inline-block;
      user-select: none;

      transition: 0.3s ease-in-out;

      
    cursor: url("../../Resources/Images/Cursors/pointer.png"), auto;
    }

    &.active > .sidebar-knob {
      background-color: #20a1f1;
      box-shadow: 1px 1px rgba(255, 255, 255, 0.9);
      margin-left: 10px;
    }
  }
}

.Content {
    position: absolute;
    top: 50px;
    left: 101px;
    right: 0px;

    > div:nth-of-type(2n) {
        background-color: #020202;
    }

    .main-skills,
    .jobs,
    .education,
    .about-me {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        min-height: 105vh;
        position: relative;

        width: calc(100vw - 125px);

        .section-title {
            position: absolute;
            top: 50px;
            left: 25px;
            font-size: 16px;
            color: gray;
            display: flex;
            flex-direction: row;

            &:before {
                content: " ";
                display: inline-block;
                height: 1px;
                width: 20px;
                margin-right: 5px;
                background: rgba(252, 252, 252, 0.8);
                align-self: center;
            }
        }
    }

    .projects {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 101vh;
        position: relative;

        width: calc(100vw - 125px);

        .section-title {
            position: absolute;
            top: 50px;
            left: 25px;
            font-size: 16px;
            color: gray;
            display: flex;
            flex-direction: row;

            &:before {
                content: " ";
                display: inline-block;
                height: 1px;
                width: 20px;
                margin-right: 5px;
                background: rgba(252, 252, 252, 0.8);
                align-self: center;
            }
        }
    }

    .welcome {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 101vh;
        position: relative;
        top: 0;

        .text-welcome-holder {
            width: 60%;

            .text {
                margin-bottom: 50px;
            }

            .text h1 {
                font-size: 2.5em;
                line-height: 1;

                span {
                    position: relative;

                    .image-angular {
                        position: absolute;
                        width: 128px;
                        height: 128px;
                        top: -120px;
                        left: 0;
                        transform: rotate(-15deg);
                    }

                    .image-csharp {
                        position: absolute;
                        width: 140px;
                        height: 140px;
                        top: -40px;
                        left: -160px;
                        transform: rotate(-35deg);
                    }
                }
            }

            .text p {
                color: rgba(223, 223, 223, 0.5);
                margin-bottom: 40px;
                margin-top: -5px;
            }

            .text a {
                margin-left: 10px;
                font-weight: 600;
                color: white;
                text-decoration: none;
                border-bottom: 1px solid #20a1f1;
                transition: background-color 0.2s;
                transition: color 0.2s;
                cursor: url("../../Resources/Images/Cursors/pointer.png"), auto;
            }

            .text a:hover,
            .text a:active {
                color: #20a1f1;
            }
        }

        .image-welcome {
            width: 35%;
            margin-right: 5%;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            user-select: none;
        }
    }

    .main-skills {
        .image-skills {
            width: 33%;
            margin-right: 5%;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            user-select: none;
        }

        .text-skills-holder {
            text-align: left;
            margin-left: 5%;
            margin-right: 2%;
            height: 75%;

            .introduction-to-skills {
                font-size: 20px;

                span {
                    margin: 0px;
                    padding: 0px;
                    line-height: 24px;
                }
            }

            .text-skills-holder-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 30px;
                padding-left: 0px;

                article {
                    background-color: rgba(154, 154, 154, 0.1);
                    border: 1px solid rgba(193, 193, 255, 0.6);
                    margin: 10px;
                    width: 25%;
                    height: fit-content;

                    div {
                        font-size: 20px;
                        padding: 5px;
                        border-bottom: 1px solid #767676;
                    }

                    .skills-inner-row {
                        &.backend {
                            &:nth-of-type(2n -1) {
                                background-color: rgba(156, 224, 255, 0.1);
                            }

                            &:nth-of-type(2n) {
                                background-color: rgba(76, 192, 246, 0.1);
                            }
                        }

                        &.frontend {
                            &:nth-of-type(2n -1) {
                                background-color: rgba(255, 75, 170, 0.1);
                            }

                            &:nth-of-type(2n) {
                                background-color: rgba(255, 158, 210, 0.1);
                            }
                        }

                        &.tools {
                            &:nth-of-type(2n -1) {
                                background-color: rgba(224, 131, 255, 0.1);
                            }

                            &:nth-of-type(2n) {
                                background-color: rgba(239, 186, 255, 0.1);
                            }
                        }

                        &.communication {
                            color: rgb(255, 255, 255);

                            &:nth-of-type(2n -1) {
                                background-color: rgba(255, 255, 255, 0.5);
                            }

                            &:nth-of-type(2n) {
                                background-color: rgba(255, 255, 255, 0.2);
                            }
                        }

                        .stars {
                            font-size: 10px;
                            background-color: transparent !important;
                        }

                        padding: 10px;
                        font-size: 16px;

                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;

                        p {
                            width: 100%;
                            margin-left: 5px;
                            text-align: left;
                        }
                    }

                    &.skills-backend {
                        border-color: #75d2fd;
                    }

                    &.skills-frontend {
                        border-color: #ff4baa;
                    }

                    &.skills-ci-cd {
                        border-color: #ebaeff;
                    }

                    &.skills-communication-and-more {
                        border-color: #ffffff;
                    }

                    p {
                        text-align: left;
                    }
                }
            }
        }
    }


    .jobs {
        .image-jobs {
            width: 20%;
            margin-right: 5%;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            user-select: none;
        }

        .text-jobs-holder {
            text-align: left;
            width: 90%;
            margin-left: 10%;
            margin-right: 2%;
            height: 60%;

            .introduction-to-jobs {
                font-size: 20px;

                span {
                    margin: 0px;
                    padding: 0px;
                    line-height: 24px;
                }
            }

            .text-jobs-holder-row {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                flex-direction: row;
                padding: 30px;
                padding-left: 0px;

                article {
                    background-color: rgba(154, 154, 154, 0.1);
                    border: 1px solid rgba(193, 193, 255, 0.6);
                    padding: 20px;
                    margin: 10px;

                    p {
                        text-align: left;
                    }

                    .job-title {
                        font-size: 1.4rem;
                        margin-bottom: 5px;
                        margin-top: 0px;
                    }

                    .job-company {
                        font-style: italic;
                        font-size: 1rem;
                        margin-bottom: 5px;
                        margin-top: 0px;
                        color: #7eaaca;
                    }

                    .job-time {
                        font-size: 1rem;
                        margin-bottom: 5px;
                        margin-top: 0px;
                        color: #9ad3fc;
                    }

                    .job-where {
                        font-size: 1rem;
                        margin-bottom: 5px;
                        margin-top: 0px;
                        color: #a2b4e4;
                    }

                    .job-what {
                        font-size: 1rem;
                        margin-bottom: 5px;
                        margin-top: 0px;
                        color: rgb(220, 245, 255);
                    }
                }
            }
        }
    }

    .education {
        .image-education {
            width: 33%;
            margin-right: 5%;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            user-select: none;
        }

        .text-education-holder {
            text-align: left;
            width: 90%;
            margin-left: 10%;
            margin-right: 2%;
            height: 60%;

            .introduction-to-education {
                font-size: 20px;

                span {
                    margin: 0px;
                    padding: 0px;
                    line-height: 24px;
                }
            }

            .text-education-holder-row {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                flex-direction: row;
                padding: 30px;
                padding-left: 0px;

                article {
                    background-color: rgba(154, 154, 154, 0.1);
                    border: 1px solid rgba(193, 193, 255, 0.6);
                    padding: 10px;
                    margin: 10px;

                    p {
                        text-align: left;
                    }

                    .education-title {
                        font-size: 1.4rem;
                        margin-bottom: 5px;
                        margin-top: 0px;

                        &.stud {
                            color: #14edff;
                        }

                        &.certificate {
                            color: darkcyan;
                        }
                    }

                    .education-company {
                        font-style: italic;
                        font-size: 1rem;
                        margin-bottom: 5px;
                        margin-top: 0px;
                        color: #7eaaca;
                    }

                    .education-time {
                        font-size: 1rem;
                        margin-bottom: 5px;
                        margin-top: 0px;
                        color: #9ad3fc;
                    }

                    .education-where {
                        font-size: 1rem;
                        margin-bottom: 5px;
                        margin-top: 0px;
                        color: #a2b4e4;
                    }

                    .education-what {
                        font-size: 1rem;
                        margin-bottom: 5px;
                        margin-top: 0px;
                        color: rgb(220, 245, 255);
                    }
                }
            }
        }
    }

    
    .projects {
        .text-projects-holder {
            text-align: left;
            width: 80%;
            margin-left: 10%;
            margin-right: 10%;
            height: 75%;

            .introduction-to-projects {
                font-size: 20px;

                span {
                    margin: 0px;
                    padding: 0px;
                    line-height: 24px;
                }
            }

            
            div.tags {
                display: inline-grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 10px;
                margin-top: 20px;
                justify-content: center;
                grid-auto-rows: 75px;
                display: flex;
                align-items: center;
                justify-content: start;

                > span {
                    color: black;
                    font-size: 16px;
                    padding: 5px;
                    text-align: center;
                    border-radius: 10%;
                    height: fit-content;
                    min-width: 50px;
                }

                > span:nth-of-type(2n -1) {
                    background-color: #a2aeec;
                }

                > span:nth-of-type(2n) {
                    background-color: #9ad3fc;
                }
            }

            .text-projects-holder-row {
                padding: 30px;
                padding-left: 0px;

                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 10px;

                article {
                    border: 1px solid #a2b4e4;
                    margin: 10px;
                    padding: 10px;
                    height: fit-content;

                    display: flex;
                    flex-direction: column;

                    position: relative;
                    width: 90%;

                    transition: 0.5s;

                    > article {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: 0.5s;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        text-align: center;
                        font-size: 16px;

                        > h4 {
                            margin: 0px;
                        }
                    }

                    &:hover > article {
                        background-color: rgb(57, 57, 57);
                        opacity: 1;
                    }

                    img {
                        height: 125px;
                    }

                    > h3 {
                        width: 100%;
                        text-align: center;
                        font-size: 16px;
                        color: #ffffff;
                        margin-bottom: 0px;
                    }

                }
            }
        }
    }

    .about-me {
        .image-aboutme {
            width: 20%;
            margin-right: 5%;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            user-select: none;
        }
        .about-me-text-holder {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            > span {
                text-align: left;
            }

            > span:nth-of-type(2n) {
                color: rgba(193, 193, 255, 0.6);
            }
        }
    }
}
